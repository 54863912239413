@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

.line {
  position:absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #33a61b; 
  transition: width 0.3s ease;
}

@media (max-width:640px){
  .line {
    display: none;
  }

  
}

li:hover .line {
  width: 100%;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #050505;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-moz-selection {
  background: #33a61b;
  color: white;
}
::-webkit-selection {
  background: #33a61b;
  color: white;
}
::selection {
  background: #33a61b;
  color: white;
}